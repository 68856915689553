<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí FAQs</h1>
    </div>
    <b-card no-body>
      <b-tabs pills card v-model="currentStep">
        <b-tab title="Danh mục" active title-link-class="btn btn-light-info">
          <FaqCategory></FaqCategory>
        </b-tab>
        <b-tab title="Câu hỏi học sinh" title-link-class="btn btn-light-info">
          <SupportTicket></SupportTicket>
        </b-tab>
      </b-tabs>
    </b-card>
  </v-app>
</template>

<script>
export default {
  name: "TabManagement",
  components: {
    FaqCategory: () => import("./FaqCategory.vue"),
    SupportTicket: () => import("./SupportTicket.vue"),
  },
  data() {
    return {
      currentStep: 0,
    };
  },
};
</script>

<style></style>
